String.prototype.replaceAt = function(index, character) {
    return this.substr(0, index) + character + this.substr(index+character.length);
};
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
function changeA() {
       /* onload code */
	
        var anchors = document.getElementsByTagName("a");

        for (var i = 0; i < anchors.length; i++) {
                str="";
                if (anchors[i].protocol=="tel:" || anchors[i].protocol=="mailto:") {
                        continue;
                }

                if (anchors[i].origin) {
                        str=anchors[i].origin;
                } else {
                     if (anchors[i].protocol=="tel:") {
                        continue;
                     }
                     if (anchors[i].protocol) {
                         str+=anchors[i].protocol+"//";
                     }
                     if (anchors[i].hostname) {
                        str+=anchors[i].hostname;
                     }
                     if (anchors[i].port) {
                        str+=":"+anchors[i].port;
                     }


                }
                if (anchors[i].pathname) {
                        str+=anchors[i].pathname;
                }

                searchObject={};
                searchstr="";
                //if (anchors[i].hostname.indexOf('quote') == 0) {
                mysearch=anchors[i].search;
                  if (mysearch) {
                        queries = mysearch.replace(/^\?/, '').split('&');
                        for( j = 0; j < queries.length; j++ ) {
                                split = queries[j].split('=');
                                searchObject[split[0]] = split[1];
                                if (split[0]=="utm_source" && app_data.campaign_source) {
                                        searchObject[split[0]] = app_data.campaign_source;
                             //           app_data.campaign_source=null;
                                }
                                if (split[0]=="utm_producer" && app_data.producer_source) {
                                        searchObject[split[0]] = app_data.producer_source;
                               //         app_data.producer_source=null;
                                }
                                if (split[0]=="utm_site" && app_data.site_source) {
                                        searchObject[split[0]] = app_data.site_source;
                                //        app_data.site_source=null;
                                }
                                if (split[0]=="utm_referer" && app_data.referer_source) {
                                        searchObject[split[0]] = app_data.referer_source;
                                //        app_data.referer_source=null;
                                }



                        }
		  }
                        if (app_data.site_source) {
                                searchObject['utm_site']=app_data.site_source;
                                setCookie('utm_site',app_data.site_source,1);
                        }
                        if (app_data.producer_source) {
                                searchObject['utm_producer']=app_data.producer_source;
				setCookie('utm_producer',app_data.producer_source,1);
                        }
                        if (app_data.campaign_source) {
                                searchObject['utm_source']=app_data.campaign_source;
				setCookie('utm_source',app_data.campaign_source,1);
                        }
                        if (app_data.referer_source) {
                                searchObject['utm_referer']=app_data.referer_source;
				setCookie('utm_referer',app_data.referer_source,1);
                        }
                        
                        for (var key in searchObject) {
                                searchstr+="&"+key+"="+searchObject[key];
                        }
                        if (searchstr.length>1) {
                                searchstr=searchstr.replaceAt(0,"?");
                        }
                        if (anchors[i].hash) {
                           anchors[i].href=str+searchstr+anchors[i].hash;
                        } else {

                           anchors[i].href=str+searchstr;
			}
        }
}

(function() {
	$(document).ready(function() {
		var s = $(".js-social-follower");
		 changeA();
		if (s.length > 0) {
			var pos = s.position();
      console.log(pos.top);
			$(window).scroll(function() {
				var windowpos = $(window).scrollTop();
				if (windowpos >= pos.top + 113) {
					s.addClass("js-stick");
				} else {
					s.removeClass("js-stick"); 
				}
			});
		};
	});
})();

function PopupCenter(url, title) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

    width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    var h = '250';
    var w = '600';

    var left = ((width / 2) - (w / 2)) + dualScreenLeft;
    var top = ((height / 2) - (h / 2)) + dualScreenTop;
    var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus();
    }
}
$(document).ready(function(){
  if ( $('.js-quick-quote').length ) {
    $(window).bind('scroll', function() {
      var scrollTop     = $(window).scrollTop(),
      elementOffset = $('.js-quick-quote').offset().top,
      distance      = (elementOffset - scrollTop);
      navHeight = $('.nav-container').height();
      eleHeight = $('.js-quick-quote').height() + 36;

      if (distance <= navHeight - eleHeight) {
        $('.js-cta-nav').addClass('open');
        $('.js-quick-quote').addClass('opac0');
      }else {
        $('.js-cta-nav').removeClass('open');
        $('.js-quick-quote').removeClass('opac0');
      }
    });
  }
});
if (!$('.js-quick-quote').length) {
	$('.js-cta-nav').addClass('js-stuck');
};

(function($) {
  $(document).ready(function() {
    $.slidebars();
  });
}) (jQuery);

$('#collapseOne').collapse("hide");

$(function() {                       
  $(".panel-group-trigger").click(function() { 
  	$(this).toggleClass("open", 100);
  });
});


// Sticky Mobile Nav

/*
* HeadsUp 1.5.6
* @author Kyle Foster (@hkfoster)
* @license MIT
*/
;(function( window, document, undefined ) {

  'use strict';



  // Extend function
  function extend( a, b ) {
    for( var key in b ) {
      if( b.hasOwnProperty( key ) ) {
        a[ key ] = b[ key ];
      }
    }
    return a;
  }

  // Throttle function (http://bit.ly/1eJxOqL)
  function throttle( fn, threshhold, scope ) {
    threshhold || ( threshhold = 250 );
    var previous, deferTimer;
    return function () {
      var context = scope || this,
          current = Date.now(),
          args    = arguments;
      if ( previous && current < previous + threshhold ) {
        clearTimeout( deferTimer );
        deferTimer = setTimeout( function () {
        previous   = current;
        fn.apply( context, args );
        }, threshhold );
      } else {
        previous = current;
        fn.apply( context, args );
      }
    };
  }

  // Class management functions
  function classReg( className ) {
    return new RegExp( '(^|\\s+)' + className + '(\\s+|$)' );
  }

  function hasClass( el, cl ) {
    return classReg( cl ).test( el.className );
  }

  function addClass( el, cl ) {
    if ( !hasClass( el, cl ) ) {
      el.className = el.className + ' ' + cl;
    }
  }

  function removeClass( el, cl ) {
    el.className = el.className.replace( classReg( cl ), ' ' );
  }

  // Main function definition
  function headsUp( selector, options ) {
    this.selector = document.querySelector( selector );
    this.options  = extend( this.defaults, options );
    this.init();
  }

  // Overridable defaults
  headsUp.prototype = {
    defaults : {
      delay       : 100,
      sensitivity : 20
    },

    // Init function
    init : function( selector ) {

      var self         = this,
          options      = self.options,
          selector     = self.selector,
          oldScrollY   = 0, 
          winHeight;

      // Resize handler function
      function resizeHandler() {
        winHeight = window.innerHeight;
        return winHeight;
      }

      // Scroll handler function
      function scrollHandler() {

        // Scoped variables
        var newScrollY = window.pageYOffset,
            docHeight  = document.body.scrollHeight,
            pastDelay  = newScrollY > options.delay,
            goingDown  = newScrollY > oldScrollY,
            fastEnough = newScrollY < oldScrollY - options.sensitivity,
            rockBottom = newScrollY < 0 || newScrollY + winHeight >= docHeight;

        // Where the magic happens
        if ( pastDelay && goingDown ) {
          addClass( selector, 'heads-up' );
        } else if ( !goingDown && fastEnough && !rockBottom || !pastDelay ) {
          removeClass( selector, 'heads-up' );
        }

        // Keep on keeping on
        oldScrollY = newScrollY;
      }

      // Attach listeners
      if ( selector ) {
        
        // Trigger initial resize
        resizeHandler();

        // Resize function listener
        window.addEventListener( 'resize', throttle( resizeHandler ), false );

        // Scroll function listener
        window.addEventListener( 'scroll', throttle( scrollHandler, 100 ), false );
      }
    }
  };

  window.headsUp = headsUp;

})( window, document );

// Instantiate HeadsUp
new headsUp('#js-mobile-navigation-container');
